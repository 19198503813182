<template>
  <v-row justify="center" class="mt-10">
    <v-col cols="12" sm="10" md="5" lg="4">
      <v-card class="elevation-12">
        <v-form @submit="register">
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{ $t("ui_registration") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              v-model.trim="form.username"
              prepend-icon="mdi-account"
              label="Аккаунт"
              placeholder="Введите адрес почты"
              required
            ></v-text-field>
            <v-text-field
              v-model.trim="form.password"
              prepend-icon="mdi-lock"
              type="password"
              label="Пароль"
              placeholder="Введите пароль"
              required
            ></v-text-field>
            <v-text-field
              v-model.trim="form.password2"
              prepend-icon="mdi-lock"
              type="password"
              label="Пароль"
              placeholder="Введите пароль повторно"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" :to="{ name: 'login' }">{{
              $t("ui_not_auth_yet")
            }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :disabled="disabled"
              @click.prevent="register"
              >{{ $t("ui_register") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        password2: "",
      },
    };
  },
  computed: {
    disabled() {
      return !this.form.username || !this.form.password || !this.form.password2;
    },
  },
  methods: {
    register() {
      if (this.disabled) return;

      api
        .request({
          name: "registration",
          payload: {
            username: this.form.username,
            password: this.form.password,
            password_2: this.form.password2,
          },
          showAlert: true,
        })
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch(() => {
          this.clear();
        });
    },
    clear() {
      this.form.password = "";
      this.form.password2 = "";
    },
  },
};
</script>
